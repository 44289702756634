import { put as putSaga, select } from 'redux-saga/effects';


import axios from 'axios';
import { get as lodashGet } from 'lodash';

import { authAction } from '../App/features/Auth/actions';
import { selectToken } from '../App/features/Auth';

export const FAILED_REQUEST = 'utils/request/FAILED_REQUEST';
export const UNAUTHORIZED_REQUEST_CODE = 'AUTHORIZATION_REQUIRED';

const appInstance = axios.create();

export function* request(params) {
  const _params = mapAxiosParams(params);
  const rootUrl = mapRootUrls(_params);
  const isRelativeUrl = _params.url.indexOf('/') === 0; 
  if (isRelativeUrl) {
    // relative url detected
    _params.url = `${rootUrl}${_params.url}`;
  }
  _params.params = _params.params || {};
  _params.headers = _params.headers || {};
  _params.headers = Object.assign({}, _params.headers, {
    'Content-Type': 'application/json',
    'Access-Control-Max-Age': 600, //cache cors preflight request results for 10min (max value for chrome)
  });
  const token = yield select(selectToken); 
  if (token && isRelativeUrl) {
    _params.headers = Object.assign({}, _params.headers, {
      Authorization: token,
    });
  }

  let result: any;

  try {
    const { data } = yield appInstance(_params);

    result = data;
  } catch (error) {
    const response = lodashGet(error, 'response', {});
    const data = lodashGet(response, 'data', {});
    const _error = lodashGet(data, 'errors', error); // use error object from response if provided

    yield errorFlow(_error);

    throw _error;
  }

  return result;
}

export function* errorFlow(error) {
  yield putSaga(failedRequest(error));

  if (error && error[0]?.message === 'Not authorized') {
    yield putSaga({ type: authAction.SIGN_OUT_USER });
  }
}

export function* get(params) {
  return yield request({ method: 'GET', ...mapAxiosParams(params) });
}

export function* post(params) {  
  return yield request({ method: 'POST', ...mapAxiosParams(params) });
}

export function* patch(params) {
  return yield request({ method: 'PATCH', ...mapAxiosParams(params) });
}

export function* destroy(params) {
  return yield request({ method: 'DELETE', ...mapAxiosParams(params) });
}

export function* put(params) {
  return yield request({ method: 'PUT', ...mapAxiosParams(params) });
}

function mapAxiosParams(params) {
  return typeof params === 'string' ? { url: params } : params;
}

function mapRootUrls(params) {
  if (params.hasOwnProperty('sso')) {
    return process.env.REACT_APP_SSO_API;
  } else if (params.hasOwnProperty('businessUtility')) {
    return process.env.REACT_APP_BUSINESS_UTILITY_API;
  } 
  else if (params.hasOwnProperty('comments')) {
    return process.env.REACT_APP_COMMENTS_API;
  }else {
    return process.env.REACT_APP_BASE_API;
  }
}

export function failedRequest(error) {
  return {
    type: FAILED_REQUEST,
    error,
  };
}

export default request;

export function getErrorMessage(e: any) {
  return lodashGet(e, '[0].message') || lodashGet(e, 'message') || 'Error on location creation.';
}
