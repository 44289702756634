import { Domain, Event, Home, Message, Pages, Settings, Work } from '@material-ui/icons';
import {
  accountMenu,
  careerMenu,
  careerProfileMenu,
  faqMenuBusiness,
  faqMenuCareer,
  gradient,
  /* jobPreferencesMenu, */
  privacyTermsMenu,
  settingsMenu,
  signedInCareerMenu,
  socialProfileMenu,
  usingNoviopusMenuBusiness,
  usingNoviopusMenuCareer,
} from 'components/Navigation/constants';

import React, { useState } from 'react';
import { businessMenuLinks } from '../Navigation/constants';

export const careerMenuLinksMainNav = {
  backRoute: '/',
  backTitle: 'Home',
  links: [
    // { href: '/help/career?id=help', title: 'Career Help' },
    // { href: '/help/career/using-noviopus', title: 'Using Career' },
    // { href: '/help/career/faq', title: 'Noviopus Career Faq' },
    // { href: '/help/career?id=contact-us', title: 'Contact Us' },
    // { href: '/help/business?id=help', title: 'Business Help Center' },
  ],
};

const homeMenu = {
  links: [
    {
      title: 'constants.career',
      href: '/search',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.settings',
      href: '/account/profile/edit',
      icon: <Settings />,
      activeIcon: (
        <>
          {gradient}
          <Settings style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};
const signedInHomeMenu = {
  backRoute: '/',
  backTitle: 'constants.home',
  links: [
    {
      title: 'constants.career',
      href: '/search',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    {
      title: 'constants.settings',
      href: '/account/profile/edit',
      icon: <Settings />,
      activeIcon: (
        <>
          {gradient}
          <Settings style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
  ],
};

const MobileHomeMenu = {
  links: [
    {
      title: 'Home',
      href: '/',
      icon: <Home />,
    },
    {
      title: 'Career',
      href: '/search',
      icon: <Work />,
      activeIcon: (
        <>
          {gradient}
          <Work style={{ fill: 'url(#linearColors)' }} />
        </>
      ),
    },
    // {
    //   title: 'Pages',
    //   href: '/pages',
    //   icon: <Pages />,
    // },
    {
      title: 'Business',
      href: '/business',
      icon: <Domain />,
    },
  ],
};

const isMobileFormat = window.innerWidth < 959;

export const getSettingsRoutes = isAuth => [
  {
    path: '/',
    menu: isMobileFormat ? MobileHomeMenu : homeMenu,
  },
  {
    path: '/search',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  {
    path: '/career/job-search/edit',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  /* {
    path: '/career/workplace/edit',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  }, */
  {
    path: '/account/profile/edit',
    menu: settingsMenu,
  },
  {
    path: '/account/resume/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/education/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/experience/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/skills/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/languages/edit',
    menu: careerProfileMenu,
  },
  {
    path: '/account/network/edit',
    menu: socialProfileMenu,
  },
  {
    path: '/account/add-contacts/edit',
    menu: socialProfileMenu,
  },
  {
    path: '/account/interests/edit',
    menu: socialProfileMenu,
  },

  {
    path: '/account/job-search/edit',
    menu: settingsMenu,
  },

  /* {
    path: '/account/workplace/edit',
    menu: jobPreferencesMenu,
  }, */
  {
    path: '/career/job-search/edit',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  },
  // {
  //   path: '/applications',
  //   menu: isAuth ? signedInCareerMenu : careerMenu,
  // },
  /* {
    path: '/career/workplace/edit',
    menu: isAuth ? signedInCareerMenu : careerMenu,
  }, */
  {
    path: '/account/security',
    menu: accountMenu,
  },
  {
    path: '/account/language',
    menu: accountMenu,
  },
  {
    path: '/account/status',
    menu: accountMenu,
  },
  {
    path: '/terms',
    menu: privacyTermsMenu,
  },
  {
    path: '/privacy',
    menu: privacyTermsMenu,
  },
  {
    path: '/help/career',
    menu: isAuth ? careerMenuLinksMainNav : homeMenu,
  },
  
];

interface menuItem {
  title: string;
  href: string;
  icon: JSX.Element;
}

export const menuMobileFirstSection: menuItem[] = [
  //Future Develop
  // {
  //   title: 'Messages',
  //   href: '/',
  //   icon: <Message />,
  // },
  // {
  //   title: 'Events',
  //   href: '/',
  //   icon: <Event />,
  // },
];

export const menuMobileSecondSection: menuItem[] = [
  {
    title: 'Home',
    href: '/',
    icon: <Home />,
  },
  {
    title: 'Career',
    href: '/search',
    icon: <Work />,
  },
  //Future Develop
  // {
  //   title: 'Pages',
  //   href: '/pages',
  //   icon: <Pages />,
  // },
  {
    title: 'Business',
    href: '/business',
    icon: <Domain />,
  },
];
