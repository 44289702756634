/**
 * This will set up the i18n language files and locale data for your app.
 */
import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LngDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

const options = {
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: 'en',
  defaultNS: 'translations',
  debug: false,
  react: {
    wait: true,
    useSuspense: false,
    defaultTransParent: 'div',
  },
};

i18n
  .use(LngDetector)
  .use(intervalPlural)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      try {
        let storedTranslations = JSON.parse(localStorage.getItem('translation') || '{}');

        if (storedTranslations[language]?.[namespace]) {
          // If translations are already stored in localStorage, use them
          callback(null, storedTranslations[language][namespace]);
        } else {
          // Fetch translations from API if not found in localStorage
          fetch(`${process.env.REACT_APP_BASE_API}/career/language/${language}`)
            .then(response => response.json())
            .then(resource => {
              // Ensure we have a valid structure
              if (!resource[language]) {
                throw new Error('Invalid translation format received.');
              }

              // Merge new translations without overwriting existing ones
              storedTranslations[language] = storedTranslations[language] || {};
              Object.assign(storedTranslations[language], resource[language]);

              // Store updated translations in localStorage
              localStorage.setItem('translation', JSON.stringify(storedTranslations));

              // Return the requested namespace
              callback(null, storedTranslations[language][namespace] || {});
            })
            .catch(error => {
              console.error('Translation fetch error:', error);
              callback(error, {});
            });
        }
      } catch (error) {
        console.error('Translation load error:', error);
        callback(error, {});
      }
    })
  )
  .init(options as any);

export default i18n;
